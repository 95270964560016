import styled from "styled-components";

export const ContentRightSideHome = styled.div`
  width: 100%;
  height: 100%;
  margin-left: auto;
  border-top-right-radius: 17px;
  border-top-left-radius: 17px;
  form {
    padding: 2rem;
    border-right: 2px solid #2f3a8f;
    border-left: 2px solid #2f3a8f;
    border-bottom: 2px solid #2f3a8f;

    .registrations-until {
      color: #d32f2f;
      display: block;
      font-style: italic;
      font-size: 15px;
      margin-bottom: 0.5rem;
    }
  }
`;

export const Plataform = styled.div`
  margin-bottom: 1.5rem;

  p {
    color: #d32f2f;
    font-style: italic;
    font-size: 15px;
    margin-bottom: 1.5rem;
    margin-top: 0;
  }
`;

export const HeaderForm = styled.div`
  background-color: #2f3a8f;
  padding: 2rem;
  border-top-right-radius: 17px;
  border-top-left-radius: 17px;

  p {
    margin: 0;
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 1.2em;
  }
`;

export const Action = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  button {
    background-color: #2f3a8f !important;
    color: white !important;
    min-width: 175.55px;
    min-height: 42.25px;
  }
`;

export const ValidateRecaptcha = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
`;
