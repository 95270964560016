import styled from "styled-components";

export const Banner = styled.div`
  background-size: cover;
  background-position: center;
  height: 165px;

  @media (orientation: landscape) {
    height: 200px;
  }
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 5rem;
  background-color: #2f3a8f;

  a {
    color: white !important;
    text-decoration: none !important;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    svg {
      margin-right: 0.3rem;
    }
  }

  img {
    width: 170px;
  }

  @media only screen and (max-width: 576px) {
    flex-direction: column;
    padding: 1.5rem 0;
    justify-content: center;
    align-items: center;
    

    img {
      order: 0;
      margin-bottom: 1.5rem;
    }

    > a {
      order: 1;
      margin-bottom: 0.5rem;
    }
  }

  @media (min-width: 577px) and (max-width: 768px) {
    flex-direction: column;
    padding: 1.5rem 0;
    justify-content: center;
    align-items: center;

    img {
      order: 0;
      margin-bottom: 1.5rem;
    }

    > a {
      order: 1;
      margin-bottom: 0.5rem;
    }
`;
