import styled from "styled-components";

export const ContentLeftSideHome = styled.div`
  color: gray;

  strong {
    color: black;
  }
  p {
    font-size: 17px;
    margin-top: 0;
    line-height: 1.5;
  }

  h2 {
    color: #2f3a8f;
    font-size: 22px;
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      margin-bottom: 1rem;
      font-size: 16px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      p {
        font-size: 15px;
      }

      .wrapper-plan {
        display: flex;
        align-items: center;
      }

      .desc-price strong {
        color: #2f3a8f;
        font-style: italic;
      }

      svg {
        margin-right: 0.5rem;
        color: #2f3a8f;
      }
    }
  }

  .text-invite {
    display: flex;
    flex-direction: column;
    line-height: 1.4;
    > span {
      color: #2f3a8f;
    }

    span {
      font-size: 22px;
      font-weight: bold;
      margin: 0;
    }

    .wrapper-rockets {
      display: flex;
      align-items: flex-end;

      .rockets {
        margin-left: 0.5rem;
        img {
          max-width: 20px;
          margin-right: 0.5rem;
        }
      }
    }

    .img-ifood-card {
      max-width: 250px;
      margin-top: 2rem;
    }

    @media only screen and (max-width: 576px) {
      margin-bottom: 1.5rem;

      .img-ifood-card {
        display: none;
      }
    }

    @media (min-width: 577px) and (max-width: 768px) {
      margin-bottom: 3rem;

      .img-ifood-card {
        display: none;
      }
    }

    @media (min-width: 769px) and (max-width: 899px) {
      margin-bottom: 3rem;
    }
  }
`;
