import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, CircularProgress, Grid } from "@mui/material";
import InputText from "../InputText";
import InputPhone from "../InputPhone";
import {
  Action,
  ContentRightSideHome,
  HeaderForm,
  Plataform,
  ValidateRecaptcha,
} from "./style";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import api from "../../utils/api";
import ifood from "../../assets/images/ifood.jpeg";

export default function RightSideHome() {
  const recaptchaRef = React.useRef(null);

  const [loading, setLoading] = useState(false);

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "all",
  });

  const onSubmit = (data) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue) {
      subscribe(data);
    } else {
      isMobile
        ? alert("Por favor, preencha o recaptcha!")
        : toast.warn("Por favor, preencha o recaptcha!");
    }
  };

  const subscribe = async (data) => {
    setLoading(true);
    try {
      const result = await api.post("/indicacao-premiada/indicar", data);
      reset();
      if (result?.data?.message) {
        isMobile
          ? alert(result.data.message)
          : toast.success(result.data.message);
      }
    } catch (error) {
      if (error?.response?.data?.error) {
        isMobile
          ? alert(error.response.data.error)
          : toast.error(error.response.data.error);
      } else {
        isMobile ? alert("Ocorreu um erro.") : toast.error("Ocorreu um erro.");
      }
    } finally {
      setLoading(false);
      recaptchaRef.current.reset();
    }
  };

  return (
    <ContentRightSideHome>
      <Plataform>
        <img src={ifood} alt="Ganhe um ifood card!" />
      </Plataform>

      <HeaderForm>
        <p>Indique seu amigo(a) aqui!</p>
      </HeaderForm>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <span className="registrations-until">
              <strong>
                * Promoção válida apenas para alunos(as) e ex-alunos(as) do
                Ligando Ideias.
              </strong>
            </span>
          </Grid>

          <Grid item xs={12}>
            <InputText
              name="name"
              label="Nome do participante da promoção"
              control={control}
              rules={{ required: true }}
              errors={errors?.name}
            />
          </Grid>

          <Grid item xs={12}>
            <InputPhone
              name="phone"
              label="WhatsApp do participante da promoção"
              control={control}
              rules={{ required: true }}
              errors={errors?.phone}
            />
          </Grid>

          <Grid item xs={12}>
            <InputText
              name="recommendation"
              label="Nome da sua indicação"
              control={control}
              rules={{ required: true }}
              errors={errors?.recommendation}
            />
          </Grid>

          <Grid item xs={12}>
            <InputPhone
              name="phoneRecommendation"
              label="WhatsApp da sua indicação"
              control={control}
              rules={{ required: true }}
              errors={errors?.phoneRecommendation}
            />
          </Grid>

          <Grid item xs={12}>
            <ValidateRecaptcha>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LcMJnEUAAAAACJNP1KNKF64nJGtmeh_glhjvAEG"
              />
            </ValidateRecaptcha>
          </Grid>

          <Grid item xs={12}>
            <Action>
              <Button size="large" type="submit" disabled={loading}>
                {loading && (
                  <CircularProgress size={20} style={{ color: "white" }} />
                )}
                {!loading && "ENVIAR MINHA INDICAÇÃO"}
              </Button>
            </Action>
          </Grid>
        </Grid>
      </form>
    </ContentRightSideHome>
  );
}
