import React from "react";
import { ContentLeftSideHome } from "./style";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import rocket from "../../assets/images/rocket.png";
import ifoodCard from "../../assets/images/ifood-card.png";

export default function LeftSideHome() {
  return (
    <ContentLeftSideHome>
      <h2>Quem disse que fazer redação não é gostoso?</h2>

      <p>
        Temos uma <strong>novidade</strong> para você,{" "}
        <strong>que já é nosso(a) ou já foi aluno(a)</strong> do{" "}
        <strong>Ligando Ideias Redação.</strong>
      </p>

      <p>
        A partir de agora, está valendo a <strong>Indicação Premiada.</strong>
      </p>

      <p>
        Indique uma pessoa que provavelmente tenha interesse em fazer nosso
        curso de redação e ganhe um <strong>iFood Card</strong>.
      </p>

      <p>🙋🏻 Só de fazer a indicação eu já ganho?</p>

      <p>
        👩🏻‍🏫 Ainda não. Estamos quase lá! Entraremos em contato com sua indicação
        e, caso ela efetue a matrícula na{" "}
        <strong>aula individual, turma noturna ou mentoria</strong>, vamos lhe
        presentear.
      </p>

      <p>🙋🏻 Qual é o valor do iFood Card?</p>

      <ul>
        <li>
          <div className="wrapper-plan">
            <CheckCircleOutlineIcon />
            <strong>AULA INDIVIDUAL AO VIVO</strong>
          </div>
          <p className="desc-price">
            💵 iFood Card no valor de <strong>R$ 70,00</strong>
          </p>
        </li>

        <li>
          <div className="wrapper-plan">
            <CheckCircleOutlineIcon />
            <strong>TURMA NOTURNA AO VIVO</strong>
          </div>
          <p className="desc-price">
            💵 iFood Card no valor de <strong>R$ 70,00</strong>
          </p>
        </li>

        <li>
          <div className="wrapper-plan">
            <CheckCircleOutlineIcon />
            <strong>MENTORIA</strong>
          </div>
          <p className="desc-price">
            💵 iFood Card no valor de <strong>R$ 45,00</strong>
          </p>
        </li>
      </ul>

      <div className="text-invite">
        <span>Preencha o formulário</span>
        <div className="wrapper-rockets">
          <span>e faça já sua indicação!</span>
          <div className="rockets">
            <img src={rocket} alt="Vamos juntos nessa!" />
          </div>
        </div>

        <img
          className="img-ifood-card"
          src={ifoodCard}
          alt="Garanta já seu iFood Card!"
        />
      </div>
    </ContentLeftSideHome>
  );
}
