import styled from "styled-components";

export const ContentFooter = styled.div`
  background-color: #2f3a8f;
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .developed-by {
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
    svg {
      margin-left: 5px;
      margin-right: 5px;
      color: red;
    }
  }

  @media only screen and (max-width: 576px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .developed-by {
      margin-top: 0.5rem;
    }
  }

  @media (min-width: 577px) and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .developed-by {
      margin-top: 0.5rem;
    }
  }
`;
